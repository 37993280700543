<template>
  <div class="orderman_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">我的订单</div>
      <div>
        <van-icon name="arrow-left" size="24" color="#fff" />
      </div>
    </div>
    <van-tabs v-model="active" background="#f9f9f9" color="var(--fc--)" class="top_tab">
      <van-tab title="全部"></van-tab>
      <van-tab title="待付款"></van-tab>
      <van-tab title="待发货"></van-tab>
      <van-tab title="待备货"></van-tab>
      <van-tab title="待自提"></van-tab>
      <van-tab title="待收货"></van-tab>
      <van-tab title="已完成"></van-tab>
      <!-- <van-tab title="待评价">待评级</van-tab> -->
    </van-tabs>
    <div style="padding-top:40px;">
      <div class="item_box" v-if="orderList.length">
        <div class="item" v-for="(item,index) in orderList" :key="index" @click="$router.push('/orderdetail/' +item.order_id + '?goods_recieve_type=' + item.goods_recieve_type)">
          <div style="padding:10px 10px;" >
            <div style="color:#999;">订单号 {{item.id}}</div>
            <div style="color:var(--fc--);" v-if="item.status == 1">待付款</div>
            <div style="color:var(--fc--);" v-if="item.status == 2 && item.goods_recieve_type == '1'">待发货</div>
            <div style="color:var(--fc--);" v-if="item.status == 2 && item.goods_recieve_type == '2'">待备货</div>
            <div style="color:var(--fc--);" v-if="item.status == 3 && item.goods_recieve_type == '2'">待自提</div>
            <div style="color:var(--fc--);" v-if="item.status == 3 && item.goods_recieve_type == '1'">待收货</div>
            <div style="color:var(--fc--);" v-if="item.status == 4">已完成</div>
          </div>
          <div class="goods_detail" v-for="(i,index) in item.goodsList" :key="index">
            <div class="detail_img">
              <img :src="i.images" alt style="width:100%;object-fit:cover;" />
            </div>
            <div
              style="width:100%;display: flex;
                        flex-direction: column;
                        justify-content: space-between;"
            >
              <div class="detail_title">
                <div>{{i.title}}</div>
                <div style="color:var(--fc--);">￥{{i.price}}</div>
              </div>
              <div class="detail_title" style="margin-top:-1em;">
                <div style="font-size:10px;color:#999;">颜色：金色</div>
                <div style="font-size:10px;color:#999;">共{{i.count}}件</div>
              </div>
              <div class="detail_btn">
                <div style="font-size:14px;color:#999;">
                  总
                  <span style="font-size:16px;color:var(--fc--);">￥{{i.total_price}}</span>
                </div>
                <div v-if="false">
                  <div class="buy_btn">再次购买</div>
                </div>
              </div>
            </div>
          </div>
          <div style="border-top:1px solid #eee;padding:10px;color:#666;">
            <span style="color:var(--fc--);font-size:16px;">
              <span style="color:#999;font-size:12px;">订单总价：</span>
              ￥{{item.total_price}}
            </span>
            <span class="buy_btn" v-if="item.status == 1" @click="$router.push('/orderdetail/' +item.order_id)">去支付</span>
            <span class="buy_btn" v-if="false" >修改地址</span>
            <span class="buy_btn" v-if="false">确认收货</span>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty
          class="custom-image"
          :image="require('../assets/images/icon/无订单.png')"
          description="没有相应订单"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MyBtn from "@/components/common/MyBtn.vue";
export default {
  components: {
    MyBtn
  },
  data() {
    return {
      active: 0,
      orderList: [],
      // orderList参数表
      activeToOrderList:[0,1,2,2,3,3,4]
    };
  },
  created() {
    this.getOrderList();
  },
  watch: {
    active(val) {
      this.getOrderList();
    }
  },
  methods: {
    async getOrderList() {
      let params = {};
      if (this.active == 0) {
        params = {
          user_id: this.$getUser()?.id
        };
      } else {
        params = {
          user_id: this.$getUser()?.id,
          status: this.activeToOrderList[this.active]
        };
      }
      let { data: res } = await this.$http.post("api/getOrderList", params);
      if (res.statusCode !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.orderList = res.data.list.filter(x => {
        if(this.active == 3){
          // 待备货
          return x.status !== 5 && x.goods_recieve_type == '2'
        } else if(this.active == 2){
          // 待发货
          return x.status !== 5 && x.goods_recieve_type == '1'
        } else if(this.active == 4){
          return x.status !== 5 && x.goods_recieve_type == '2'
        } else if(this.active == 5){
          return x.status !== 5 && x.goods_recieve_type == '1'
        }
        return x.status !== 5;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.buy_btn {
  width: 5rem;
  height: 2rem;
  font-size: .75rem;
  color: #fff;
  background-color: var(--fc--);
  border-radius:2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
.orderman_box {
  height: calc(100vh);
  background-color: #f9f9f9;
  padding: 10px 0;
  padding-top: 5.5rem;
  position: relative;
  .top_tab{
    position: fixed;
    top: 5.5rem;
    left:0;
    width: 100%;
  }
  .item_box {
    padding: 20px 0;
    .item {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      background-color: #fff;
      // border-radius: 5px;
      // box-shadow: 0 0 5px 5px #eee;
      margin-bottom: 10px;
      > div {
        display: flex;
        justify-content: space-between;
      }
      .goods_detail {
        padding: 20px 10px;
        padding-bottom: 20px;
        border-top: 1px solid #eee;
        .detail_img {
          width: 90px;
          height: 90px;
          background-color: #eee;
          flex-shrink: 0;
          margin-right: 20px;
          display: flex;
          align-items: center;
          img {
            height: 100%;
          }
        }
        .detail_title {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          //padding-right: 20px;
        }
        .detail_btn {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
/deep/.van-tab--active {
  color: var(--fc--);
  font-weight: 550;
}
</style>