import { render, staticRenderFns } from "./OrderMan.vue?vue&type=template&id=00f39aaf&scoped=true&"
import script from "./OrderMan.vue?vue&type=script&lang=js&"
export * from "./OrderMan.vue?vue&type=script&lang=js&"
import style0 from "./OrderMan.vue?vue&type=style&index=0&id=00f39aaf&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f39aaf",
  null
  
)

export default component.exports